.loading-app {
	opacity:    0.5; 
  	background: #ffffff; 
  	width:      100%;
  	height:     100%; 
  	z-index:    10000000000;
  	top:        0; 
  	left:       0; 
  	position:   fixed; 
}

.circle {
    top: 50%;
    margin: 0;
  	position: absolute;
  	left: 50%;
}